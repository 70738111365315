import React from 'react'
import styles from './Services.module.scss'

const brands_items = [
    {
        title: 'World of Dance',
        description: '世界50都市で開催されるダンスコンペティション。全世界から世界1位を目指して集まる5日間のビックイベント。Youtube視聴再生数延べ全世界4億人を超える名実ともに世界一のダンスイベント',
        img_url: 'thumb_world-of-dance_services.jpg',
        link_url: 'http://worldofdancejp.com/'
    },
    {
        title: 'Showstopper',
        description: '1967年より開催された老舗ダンスコンテスト。BeyoncéやBritney Spearsがここからデビューをした事は有名であり、Final参加者は2.000人にもなる全米トップスケールのコンテスト。',
        img_url: 'thumb_showstopper_services.jpg',
        link_url: 'http://showstopperjapan.info/'
    },
    {
        title: 'Dance the Magic',
        description: '<strong>@Disneyland California</strong><br />California Disneylandで開催される3日間のパレードとステージショーに出演。本場Disneylandで一般来場者の前で踊る体験プログラム。',
        img_url: 'thumb_dance-the-magic_services.jpg',
        link_url: ''
    },
    {
        title: 'Rams Junior Ceer Leading Program',
        description: '自社開催イベント “Top of Cheer” で行われるチアコンテストにて、BEST３がUSに渡航し、RAMSゲーム内で行われる、ハーフタイムショーをはじめ様々なステージに参加するプログラムです。国内で唯一、NFLゲームのステージをリアル体験できます。',
        img_url: 'thumb_rams_services.jpg',
        link_url: ''
    // },
    // {
    //     title: 'One Stage',
    //     description: 'ステージ作りにおいて欠かせない、立ち位置や、フォーメーションを簡単にビジュアライズ化することが可能なダンスアプリケーション<br /><strong>商標 ONESTAGE 登録商標第6051264号</strong>',
    //     img_url: 'thumb_onestage_services.jpg',
    //     link_url: 'https://onestage.tokyo/'
    // },
    // {
    //     title: 'あんさんぶるスターズ！',
    //     description: '2.5次元のアイドルグループ あんさんぶるスターズ！に関わる、LIVE・ゲーム・アニメのキャスティング・振付を担当。年間26アーティストのダンスを管理。',
    //     img_url: 'thumb_ensenble-stars_services.jpg',
    //     link_url: 'https://stars.happyelements.co.jp/'
    }
];

const services_items = [
    `・アーティスト、ダンサーの発掘育成`,
    `・各種イベント企画、制作、斡旋、実施運営`,
    `・WEB、モバイルアプリケーション受託開発`,
    `・ソフトウェアの開発、販売`,
    `・衣料品・日用品販売`
];

const BrandsItem = ({item, children}) => {
    const isLink = item.link_url;

    if (isLink) {
        return (
            <a className={styles.itemInner} href={isLink} target="_blank" rel="noopener noreferrer">{children}</a>
        );
    } else {
        return (
            <div className={styles.itemInner}>{children}</div>
        );
    }
}

const ServicesItem = ({item}) => {
    return (
        <li>{item}</li>
    );
}

export default () => (
    <section className={`${styles.services} l-wrapper l-container`}>
        <div>
            <h2 className={`head`}>Services</h2>
            <h3 className={`${styles.title} ${styles.head}`}>”ダンスエンターテイメント”の<br className="hide-md" />最先端コンテンツがラインナップ！</h3>
            <ul className={styles.data}>
                {brands_items.map(item => {
                    const descHtml = item.description;
                    const linkUrl = item.link_url;
                    const linkItem = <a className="hide-pc" href={linkUrl} target="_blank" rel="noopener noreferrer">Webサイトへ</a>

                    return (
                        <li className={styles.item}>
                            <BrandsItem item={item}>
                                <>
                                    <span className={styles.itemTitle}>{item.title}</span>
                                    <span className={styles.itemThumb}>
                                        <span>
                                            <img src={`/assets/${item.img_url}`} alt={item.title} />
                                        </span>
                                    </span>
                                    <span className={styles.itemDesc}>
                                        <span dangerouslySetInnerHTML={{__html:descHtml}} />
                                        {linkUrl && linkItem}
                                    </span>
                                </>
                            </BrandsItem>
                        </li>
                    );
                })}
            </ul>
            <div className={styles.divider}></div>
            <h3 className={styles.title}>事業概要</h3>
            <ul className={styles.listData}>
                {services_items.map(item => <ServicesItem item={item} />)}
            </ul>
        </div>
    </section>
)
